.Testimonialcontainer{
    @media (max-width:900px){
        padding-top: 30px;
        margin-top: 0px;
        background: #F8FAFC;
    }
}

.Testimonialtitles{
    @media (max-width:900px){
        font-size: 26px;
        text-align: center;
        width: 60%;
        line-height:35px;
    }
}

.Testimonialp{
    @media (max-width:900px){
        font-size: 14px;
        width: 100%;
    }
}

.cardsmaincontainer{
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 40px 0px 40px 0px;
    @media (max-width:900px){
        display: none;
    }
}

.cardscontainerdiv{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 380px;
}

.singleCardContainer{
    box-shadow: 
        0px 8px 10px -6px #0F172A1A, 
        0px 20px 25px -5px #0F172A1A;
    border-radius:16px;    
}

.descriptionp{
    font-size: 18px;
    line-height: 28px;
}

.namep{
    font-size: 16px;
    font-weight: 700;
}

.postp{
    font-size: 14px;
}

.namepostDiv{
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #F1F5F9
}

.mobilecardscontainerdiv{
    display: none;
    @media (max-width:900px){
        display: block;
        margin-top: 30px;
        .mobiletestimonialcardnpi{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #F1F5F9;
            height: auto;
        }
        .mobilecardscontainer{
            display: flex;
            flex-direction: column;
            box-shadow: 0px 8px 10px -6px #0F172A1A,0px 20px 25px -5px #0F172A1A;
            padding: 16px;
            border-radius:16px;
            gap: 16px;
            background-color: #FFFFFF;
            width: 100%;
            height: auto;
        }
        .mobilecardwrapper{
                height: auto;
        }
        .mobilecarddesc{
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            width: 75%;
        }
        .mobilecardname{
            font-weight: 700;
            line-height: 28px;
        }
        .mobilecardpost{
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
        }
        .mobilecardprevarrow{
            display: flex;
            justify-content: center;
            align-items:center;
            border: 1px solid #646a69;
            border-radius:50%;
            cursor:pointer;
            margin: 5px;
            margin-right: 0px;
            height: 30px;
            width: 30px;
            font-size: 1rem;
        }
        .mobilecardsarrowdiv{
            display: flex;
            justify-content: end;
            gap: 0.5rem;
        }
    }
}