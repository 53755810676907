.OfferDisccontainer {
    padding: 0px 20px 40px 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
    opacity: 0.4; 
    transition: opacity 0.3s ease, border-bottom 0.3s ease; 
    @media (max-width: 900px) {
        width: 100%;
    }
}
.OfferDisccontainer.active {
    opacity: 1; 
}
.OfferDisccontainer.inactive {
    opacity: 0.4;
}
.OfferDiscnumber {
    font-size: 36px; 
    font-weight: bold; 
    margin: 0; 
    @media (max-width: 900px) {
        font-size: 28px;
    }
}
.offerdisch2 {
    font-size: 24px;
    font-weight: 700;
    width: fit-content;
    margin-top: 10px;
    @media (max-width: 900px) {
        font-size: 20px;
    }
}

.offerdisch2.active{
    border-bottom: 4px solid #15808D; 
    color:#222222;
} 

.offerdisch2.inactive{
    border-bottom: none;
    color: #BDBDBD;
} 

.offerdiscp {
    font-size: 18px;
    color: #666666;
    margin-top: 10px;
    @media (max-width: 900px) {
        font-size: 14px;
    }
}

.OfferDiscnumber.inactive{
    background: #BDBDBD;
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent; 
}

.OfferDiscnumber.active{
    background: linear-gradient(180deg, #15808D 0%, #015863 100%);
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent; 
}