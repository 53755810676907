.HelpBusiness{
    width: 50%;
    color: #494A52;
    @media (max-width: 900px){
        width: 100%;
        padding: 0px 20px 0px 20px;;
        font-size: 14px;
   }
}

.HelpBusinessHeading{
    line-height: 60px;
    @media (max-width: 900px){
        width: 100%;
        padding: 0px 20px 0px 20px;
        font-size: 26px;
        line-height: 40px;
   }
}

.HelpCardscontainer{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    justify-items: center;
    gap: 40px 0px;
    width: 90%;
    margin: auto;
    margin-top: 40px;
    @media (max-width: 900px){
       grid-template-columns: repeat(1,1fr);
       width: 100%;
       gap: 10px 0px;
       margin-top: 20px;
    }
}



.HelpCards {
   width: 600px;
   padding: 25px 16px;
   position: relative;
   background: white;
   cursor: pointer;
   border-radius: 5px; 
   overflow: hidden; 
   @media (max-width: 900px) {
       width: auto;
       padding: 20px 16px;
   }
   &:hover {
       box-shadow: 0px 10px 10px 0px #D7D7D717;
   }
   &:hover::before {
       content: '';
       position: absolute;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       border-radius: inherit; 
       border: 2px solid transparent; 
       background: linear-gradient(white, white) padding-box, 
                   linear-gradient(133.13deg, #15808D 0%, rgba(49, 188, 205, 0) 51.65%) border-box; 
       pointer-events: none; 
       z-index: 1; 
   }
   h3, p, img {
       position: relative; 
       z-index: 2; 
   }
   h3 {
       margin-top: 8px;
       font-size: 22px;
       color: #000000;
       font-weight: 600;
   }
   p {
       margin-top: 16px;
       font-size: 18px;
       font-weight: 400;
       color: #4A5568;
       @media (max-width: 900px) {
           font-size: 14px;
       }
   }
}
