.offerp{
    width: 70%;
    text-align: center;
    font-size: 18px;
    @media (max-width:900px) {
        width: 100%;
        font-size: 14px;
    }
}

.offerwrapper{
    padding: 0px 20px;
    display: flex;
}

.offerh1{
    @media (max-width:900px) {
        font-size: 26px;
    }
}


.offerwrapper {
    display: flex;
    padding: 0px 20px;
}
.leftcontainer {
    flex: 1;
}
.rightcontainer {
    flex: 1;
    position: relative;
    @media (max-width:900px){
        display: none;
    }
}
.imageActive {
    opacity: 1;
    transition: opacity 1s ease-in-out;
}
.imageInactive {
    opacity: 0.4;
    transition: opacity 1s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
}
.rightcontainer {
    flex: 1;
    position: relative;
}
.imageActive {
    display: block;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}
.imageInactive {
    display: none; /* Ensures inactive images are hidden */
}