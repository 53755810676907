.statsh1{
    @media (max-width: 900px){
        font-size: 26px;
        width: 100%;
        padding: 0px;
        margin: 0px;
        line-height: normal;
    }
}

.statsdiv{
    @media (max-width: 900px){
         width: 100%;
    }
}

.statsp{
    margin-top: 20px;
    width: 80%;
    @media (max-width: 900px){
        padding: 20px;
        font-size: 14px;
   }
}

.statscount{
    @media (max-width: 900px){
        font-size: 26px;
        text-align: left;
   }
}

.statscountp{
    @media (max-width: 900px){
        font-size: 9px;
        text-align: left;
   }
}

.statscountcontainer{
    @media (max-width: 900px){
        width: 100%;
   }
}

.statscountmaincontainer{
    padding-left: 50px;
    @media (max-width: 900px){
        gap: 2px;
        padding: 15px;
        margin-top: 20px;
   }
}

.statsmaincontainer{
    @media (max-width: 900px){
       padding: 40px 0px;
   }
}