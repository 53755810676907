.hiringtitles{
    @media (max-width:900px){
        font-size: 26px;
        text-align: center;
        width: 60%;
        line-height:35px;
    }
}

.sectioncontainer{
    @media (max-width:900px){
        padding-top: 30px;
    }
}

.hiringp{
    
    @media (max-width:900px){
        font-size: 14px;
        width: 100%;
    }
}

.hiringcardscontainer{
    @media (max-width:900px){
        margin-top: 30px;
    }
}
